import { Directive, HostListener } from '@angular/core';

/**
 * @description Used on elements inside of MatMenus to prevent clicks from
 * closing the menu.
 *
 * The default behavior of MatMenus is to close when something is clicked
 * within it. This directive prevents the click event from propagating up
 * to the MatMenu element.
 */
@Directive({
    selector: '[gcPreventMenuClose]',
    standalone: false
})
export class PreventMenuCloseDirective {

    @HostListener('click', ['$event']) onClick(event: MouseEvent) {
        event.stopPropagation();
    }
}
