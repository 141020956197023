import { Component, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'buyiq-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EmptyStateComponent {
    @HostBinding('class.empty-state') emptyStateClass = true;
    @Input() heading: string;
    @Input() subheading: string;
    @Input() icon: string;
}
