import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FocusService } from '@buyiq-core/focus/focus.service';
import { NotificationService } from '@buyiq-core/notifications/notification.service';
import { notificationAnimation, NotificationRef } from '@buyiq-shared/models/notification';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'buyiq-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [notificationAnimation],
    standalone: false
})
export class NotificationsComponent implements OnInit, OnDestroy {
    notifications = new Array<NotificationRef>();

    private readonly unsubscribe = new Subject<boolean>();

    constructor(
        private notificationService: NotificationService,
        private focusService: FocusService,
        private cdRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.notificationService.notificationChanges
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe(notifications => {
                this.notifications = notifications;
                this.cdRef.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.notificationService.dismissAll();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    onDismissNotification(notification: NotificationRef): void {
        this.notificationService.dismiss(notification.id);
        this.focusService.requestSearchInputFocus();
    }
}
