import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { SortOption, SortOptionChangeEvent } from '@buyiq-shared/models/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'buyiq-sort-menu',
    templateUrl: './sort-menu.component.html',
    styleUrls: ['./sort-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SortMenuComponent implements OnInit, OnChanges, OnDestroy {
    @Input() sortOptions: Array<SortOption>;
    @Input() defaultSortType: string;
    @Input() clearSort: Subject<boolean>;
    @Output() changeSortOption = new EventEmitter<SortOptionChangeEvent>();

    private selectedSortType: string;
    private readonly unsubscribe = new Subject<boolean>();

    ngOnInit(): void {
        const selectedSort = this.sortOptions.find(sortOption => sortOption.isSelected);
        this.selectedSortType = selectedSort && selectedSort.sortType;
        this.clearSort
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.resetSort());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sortOptions && !changes.sortOptions.firstChange) {
            const selectedOption = this.sortOptions.find(sortOption => sortOption.isSelected);
            this.setSelectedSortOption(selectedOption.sortType);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    onSelectSortOption(event: SortOptionChangeEvent): void {
        const nextSelectedOption = event.sortOption;

        if (this.selectedSortType === nextSelectedOption.sortType) {
            this.toggleSortOptionDirection();
        } else {
            this.setSelectedSortOption(nextSelectedOption.sortType);
        }

        this.changeSortOption.emit(event);
    }

    private resetSort(): void {
        const defaultSort = this.getSortOptionByType(this.defaultSortType);
        defaultSort.sortDirection = defaultSort.defaultSortDirection;
        this.setSelectedSortOption(defaultSort.sortType);

        const event = new SortOptionChangeEvent({
            sortOption: defaultSort
        });
        this.changeSortOption.emit(event);
    }

    private setSelectedSortOption(sortType: string): void {
        const currentSelectedOption = this.getSortOptionByType(this.selectedSortType);
        currentSelectedOption.isSelected = false;
        currentSelectedOption.sortDirection = currentSelectedOption.defaultSortDirection;

        const nextSelectedOption = this.getSortOptionByType(sortType);
        nextSelectedOption.isSelected = true;
        nextSelectedOption.sortDirection = nextSelectedOption.defaultSortDirection;

        this.selectedSortType = sortType;
    }

    private getSortOptionByType(sortType: string): SortOption {
        return this.sortOptions.find(option => option.sortType === sortType);
    }

    private toggleSortOptionDirection(): void {
        const sortOption = this.getSortOptionByType(this.selectedSortType);
        sortOption.sortDirection = sortOption.sortDirection === 'desc' ? 'asc' : 'desc';
    }
}
