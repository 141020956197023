import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'gc-fab-container',
    templateUrl: './fab-container.component.html',
    styleUrls: ['./fab-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FabContainerComponent {
    @Input() label: string;
    @Input() icon: string;
    @Input() route?: string | Array<any>;
    @Input() disabled: boolean;
    @Output() triggerAction = new EventEmitter();

    onTriggerAction(): void {
        this.triggerAction.emit();
    }
}
