import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AnalyticsService } from '@buyiq-core/analytics/analytics.service';
import { FocusService } from '@buyiq-core/focus/focus.service';
import {
    AnalyticsAction,
    AnalyticsCategory,
    AnalyticsEvent,
} from '@buyiq-core/models/analytics';
import { InputType } from '@buyiq-core/models/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScriptLoaderService } from '../script-loader.service';
import { UserService } from '@buyiq-core/user/user.service';
import { FeatureFlag } from '@buyiq-core/models/feature-flags';

@Component({
    selector: 'buyiq-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SearchInputComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
    @HostBinding('class.mat-elevation-z3') elevationCssClass = true;
    @ViewChild('searchInput') searchInputElement: ElementRef;
    @Input() textOverride: string;
    @Input() maxLength: number;
    @Input() placeholder = 'Search';
    @Input() allowLetterInput = false;
    @Input() useFullKeyboard = false;
    @Input() hideKeyboardOnFocus = true;
    @Input() preserveInput = false;
    @Input() qBrowser = false;
    @Input() allowBlankSearch = false;
    @Input() hideCameraOverride = false;
    @Output() triggerSearch = new EventEmitter<string>();

    allowCameraScanning = false;
    inputType = InputType.Telephone;
    isReadonly: boolean;
    formControl: FormControl;
    cameraOn: boolean;

    private readonly unsubscribe = new Subject<boolean>();

    constructor(
        private userService: UserService,
        private analyticsService: AnalyticsService,
        private focusService: FocusService,
        private cdRef: ChangeDetectorRef,
        private scriptLoader: ScriptLoaderService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.formControl = new FormControl(this.textOverride || '');
        this.cameraOn = false;

        // Telephone is used for numeric input because the keyboard is most consistent on mobile devices
        this.inputType = this.useFullKeyboard
            ? InputType.Search
            : InputType.Telephone;
        this.loadScript();

        // eslint-disable-next-line @typescript-eslint/dot-notation
        window['angularComponentReference'] = {
            component: this,
            zone: this.ngZone,
            ipcScanned: (upc: string) => this.ipcScanned(upc),
        };

        this.userService
            .getCurrentUser()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((user) => {
                this.allowCameraScanning = user.features.includes(
                    FeatureFlag.CameraScanning
                );
                if (this.hideCameraOverride === true) {
                    this.allowCameraScanning = false;
                }
                this.cdRef.detectChanges();
            });
    }

    ngAfterViewInit(): void {
        this.focusService.searchInputFocusRequests
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                // This prevents the display of the on-screen keyboard in mobile browsers. When focused,
                // a readonly element will not trigger the on-screen keyboard in mobile browsers
                this.isReadonly = this.hideKeyboardOnFocus;
                this.cdRef.detectChanges();

                this.searchInputElement.nativeElement.focus();

                setTimeout(() => {
                    // The timeout is needed to allow focus to be triggered with the readonly attribute set.
                    // Otherwise, the change detection cycle will ignore the initial readonly value
                    this.isReadonly = false;
                    this.cdRef.detectChanges();
                }, 10);
            });

        this.focusService.requestSearchInputFocus();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.useFullKeyboard && !changes.useFullKeyboard.firstChange) {
            this.inputType = this.useFullKeyboard
                ? InputType.Search
                : InputType.Telephone;
            this.cdRef.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    onTriggerCameraSearch(searchText: string): void {
        if (searchText) {
            this.triggerSearch.emit(searchText);
        }
    }

    onTriggerSearch(): void {
        const searchText = ((this.formControl.value as string) || '').trim();
        if (searchText || this.allowBlankSearch) {
            this.triggerSearch.emit(this.formControl.value);

            if (!this.preserveInput) {
                this.formControl.setValue('', { emitEvent: false });
            }

            this.focusService.requestSearchInputFocus();
        }
    }

    onCameraOn(): void {
        this.cameraOn = !this.cameraOn;
        this.trackCameraOn();
    }

    onCameraOff(): void {
        this.cameraOn = false;
    }

    ipcScanned(upc: string): void {
        this.formControl.patchValue(upc);
        this.onTriggerSearch();
    }

    loadScript(): void {
        if (this.qBrowser === true) {
            this.scriptLoader.load('qbrowser');
        } else {
            this.scriptLoader.load('webhub');
        }
    }

    private trackCameraOn(): void {
        const event = new AnalyticsEvent({
            category: AnalyticsCategory.CameraScanning,
            action: AnalyticsAction.OpenCamera,
            trackedValue: '',
        });

        this.analyticsService.trackEvent(event);
    }
}
