import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeedbackService } from '@buyiq-core/feedback/feedback.service';
import { Feedback, FeedbackData, FeedbackFormObject } from '@buyiq-core/models/feedback';
import { environment } from '@buyiq-environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'buyiq-feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FeedbackDialogComponent implements OnDestroy, OnInit {
    private unsubscribe = new Subject<boolean>();
    feedbackForm: FormGroup;
    loading = false;
    buildNumber: string;

    constructor(
        public dialogRef: MatDialogRef<FeedbackDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FeedbackData,
        private formBuilder: FormBuilder,
        private feedbackService: FeedbackService
    ) {
    }

    ngOnInit(): void {
        this.buildNumber = environment.buildVersion;
        this.createForm();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    createForm() {
        this.feedbackForm = this.formBuilder.group({
            firstName: this.data.user.profile.firstName,
            lastName: this.data.user.profile.lastName,
            email: ['', [Validators.required, Validators.email]],
            feedback: ['', Validators.required]
        });
    }

    onSubmit(): void {
        this.loading = true;

        const feedback = new Feedback({
            userId: this.data.user.id,
            content: this.constructFeedback(this.feedbackForm.value),
            application: 'BUYiQ'
        });

        this.feedbackService.createFeedback(feedback)
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe(feedbackResponse => {
                this.loading = false;

                if (feedbackResponse) {
                    this.dialogRef.close(feedback);
                }
            });
    }

    get email(): AbstractControl {
        return this.feedbackForm.get('email');
    }

    get feedback(): AbstractControl {
        return this.feedbackForm.get('feedback');
    }

    /**
     * Returns feedback with additional metadata
     *
     * @note `.replace()` and `.trim()` are used to remove indentation whitespace
     *
     */
    private constructFeedback(formValue: FeedbackFormObject): string {
        return `
            page: ${this.data.page},
            buildVersion: ${environment.buildVersion},
            name: ${formValue.firstName} ${formValue.lastName},
            email: ${formValue.email},
            feedback: ${formValue.feedback}
        `.replace(/\s+/g, ' ').trim();
    }
}
