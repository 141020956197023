import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChainStore } from '@buyiq-core/models/chain-store';
import { User } from '@buyiq-core/models/user';

@Component({
    selector: 'buyiq-store-select',
    templateUrl: './store-select.component.html',
    styleUrls: ['./store-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StoreSelectComponent implements OnInit {
    stores: Array<ChainStore>;
    selectedStore: ChainStore;

    constructor(
        public dialogRef: MatDialogRef<StoreSelectComponent>,
        @Inject(MAT_DIALOG_DATA) public data: User
    ) { }

    ngOnInit(): void {
        this.stores = this.data.stores;
        this.selectedStore = this.stores[0];
    }

    onSubmit(): void {
        this.dialogRef.close(this.selectedStore);
    }
}
