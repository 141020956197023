import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DialogService } from '@buyiq-core/dialog/dialog.service';
import { FeedbackDialogComponent } from '@buyiq-core/feedback/feedback-dialog/feedback-dialog.component';
import { DialogConfig } from '@buyiq-core/models/dialog';
import { FeedbackData } from '@buyiq-core/models/feedback';
import { NavigationService } from '@buyiq-core/navigation/navigation.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'buyiq-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FeedbackComponent implements OnInit, OnDestroy {
    private unsubscribe = new Subject<boolean>();
    feedbackData: FeedbackData = {};

    constructor(
        public snackBar: MatSnackBar,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogService,
        private navigationService: NavigationService
    ) {
    }

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter((event: any) => event instanceof NavigationEnd),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                const routeData = this.navigationService.findRouteData(this.activatedRoute.snapshot);
                if (routeData.title) {
                    this.feedbackData.page = routeData.title;
                }

                if (routeData.user) {
                    this.feedbackData.user = routeData.user;
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    openSnackBar() {
        this.snackBar.open('Thank you for the feedback.', null, {
            duration: 2500
        });
    }

    onSendFeedback(): void {
        const dialogConfig = new DialogConfig({
            component: FeedbackDialogComponent,
            data: this.feedbackData
        });

        this.dialogService.show(dialogConfig)
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe((response: boolean) => {
                if (response) {
                    this.openSnackBar();
                }
            });
    }
}
