import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { ApiSortDirection } from '@cia-front-end-apps/shared/api-interaction';
import { SortOption, SortOptionChangeEvent } from '@buyiq-shared/models/sort';

@Component({
    selector: 'buyiq-sort-menu-option',
    templateUrl: './sort-menu-option.component.html',
    styleUrls: ['./sort-menu-option.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SortMenuOptionComponent implements OnInit, OnChanges {
    @Input() sortOption: SortOption;
    @Input() isSelected: boolean;
    @Input() sortDirection: ApiSortDirection;
    @Output() selectOption = new EventEmitter<SortOptionChangeEvent>();

    displayName: string;
    isDescending: boolean;

    ngOnInit(): void {
        this.displayName = this.sortOption ? this.sortOption.displayName : 'Default Option';
        this.updateSortIcon();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sortDirection && !changes.sortDirection.firstChange) {
            this.updateSortIcon();
        }
    }

    @HostListener('click')
    onSelectOption(): void {
        const event = new SortOptionChangeEvent({
            sortOption: this.sortOption
        });
        this.selectOption.emit(event);
    }

    private updateSortIcon(): void {
        this.isDescending = this.sortDirection === 'desc';
    }
}
