import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { AuthService } from '@buyiq-core/auth/auth.service';
import { ProgressBarService } from '@cia-front-end-apps/shared/progress-bar';
import { BatchSummary } from '@buyiq-shared/models/batch-summary';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ToolbarService } from './toolbar.service';

@Component({
    selector: 'buyiq-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ToolbarComponent implements OnInit, OnDestroy {
    @Output() openNavigationMenu = new EventEmitter<void>();

    isAuthorized = false;
    progressBarMode: ProgressBarMode = 'determinate';
    batchSummary: BatchSummary;
    pageTitle: string;

    private readonly unsubscribe = new Subject<boolean>();

    constructor(
        private progressBarService: ProgressBarService,
        private authService: AuthService,
        private cdRef: ChangeDetectorRef,
        private toolbarService: ToolbarService
    ) { }

    ngOnInit(): void {
        const progressBarState = this.progressBarService.getState()
            .pipe(
                tap(state => {
                    this.progressBarMode = state === 'active' ? 'indeterminate' : 'determinate';
                })
            );

        const loginState = this.authService.getLoginState()
            .pipe(
                tap(isAuthorized => {
                    this.isAuthorized = isAuthorized;
                })
            );

        const batchSummaryState = this.toolbarService.getActiveBatchSummary()
            .pipe(
                tap(batchSummary => {
                    this.batchSummary = batchSummary;
                })
            );

        const pageTitleState = this.toolbarService.getPageTitle()
            .pipe(
                tap(pageTitle => {
                    this.pageTitle = pageTitle;
                })
            );

        merge(
            progressBarState,
            loginState,
            batchSummaryState,
            pageTitleState
        )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.cdRef.detectChanges());
    }

    ngOnDestroy(): void {
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    onOpenNavigationMenu() {
        this.openNavigationMenu.emit();
    }
}
