import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { VendorAttribute } from '@buyiq-app/product/models/product';
import { DialogService } from '@buyiq-core/dialog/dialog.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ProductService } from '@buyiq-app/product/services/product.service';

@Component({
    selector: 'buyiq-quantity-input',
    templateUrl: './quantity-input.component.html',
    styleUrls: ['./quantity-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class QuantityInputComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('quantityInput') quantityInputElement: ElementRef<HTMLInputElement>;
    @Input() isReadOnly: boolean;
    @Input() isValid: boolean;
    @Input() valueOverride = 0;
    @Input() vendorAttribute: VendorAttribute;
    @Input() displayCorrectionDialog = true;
    @Output() markAsPending = new EventEmitter<void>();
    @Output() commitValue = new EventEmitter<number>();

    formControl: FormControl;
    private hasPendingChange = false;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private productService: ProductService,
        private dialogService: DialogService
    ) {
    }

    ngOnInit(): void {
        this.formControl = new FormControl(this.valueOverride ?? 0);
        this.formControl.valueChanges
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.markAsPending.emit();
                this.hasPendingChange = true;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.valueOverride && !changes.valueOverride.firstChange) {
            const value = this.valueOverride ? Number.parseInt(this.valueOverride.toString(), 10) : 0;
            this.formControl.setValue(value, { emitEvent: false });
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onPressEnter(): void {
        // There is a blur listener which commits the current value, so by calling this
        // we blur the element and commit the value without having duplicate events emitted.
        this.quantityInputElement.nativeElement.blur();
    }

    onBlurInput(): void {
        const rawValue = this.formControl.value;
        const quantity = rawValue ? Number.parseInt(rawValue, 10) : 0;

        if (!this.isReadOnly && this.hasPendingChange) {
            this.commitValue.emit(quantity);
            this.hasPendingChange = false;
        }
    }


    onClickInput(): void {
        const rawValue = this.formControl.value;
        const quantity = rawValue ? Number.parseInt(rawValue, 10) : 0;
        const displayCorrectionDialog = this.displayCorrectionDialog && !this.isReadOnly && !this.isValid;

        if (displayCorrectionDialog) {
            const correctedQuantity = this.productService.adjustQuantityToVendorRequirements(
                this.valueOverride,
                this.vendorAttribute
            );
            const title = 'Correct Quantity?';
            const content = [
                `Quantity (${quantity}) does not meet vendor required order minimum or order multiple.`,
                `Correct quantity to ${correctedQuantity}?`
            ].join(' ');

            this.dialogService.confirm(title, content)
                .pipe(
                    filter(confirmed => confirmed),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(() => {
                    this.commitValue.emit(correctedQuantity);
                    this.hasPendingChange = false;
                });
        } else {
            this.onFocusInput();
        }
    }

    onFocusInput(): void {
        if (!this.isReadOnly && this.isValid) {
            this.quantityInputElement.nativeElement.setSelectionRange(0, this.quantityInputElement.nativeElement.value.length);
        }
    }
}
