import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import Quagga from '@ericblade/quagga2';
import { SelectedCamera } from '@buyiq-shared/models/selected-camera';


@Component({
    selector: 'buyiq-product-scan',
    templateUrl: './product-scan.component.html',
    styleUrls: ['./product-scan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProductScanComponent implements OnInit {
    @Output() triggerSearch = new EventEmitter<string>();
    @Output() cameraOff = new EventEmitter();

    cameraOn: boolean;
    lastGoodUpcCount: number;
    lastUpcProcessed: string;
    selectedCamera = new SelectedCamera();
    cameras: SelectedCamera[];

    ngOnInit(): void {
        this.cameraOn = false;
        this.lastGoodUpcCount = 0;
        this.lastUpcProcessed = '';
        this.displayCameraSelection();
        this.showCamera();
    }

    displayCameraSelection(): void {
        Quagga.CameraAccess.enumerateVideoDevices()
            .then(devices => {
                this.cameras = [];
                devices.forEach(device => {
                    this.cameras.push({
                        label: device.label,
                        id: device.deviceId
                    });
                    this.selectedCamera.id = device.deviceId;
                });
            });
    }

    showCamera(): void {
        Quagga.init({
                inputStream: {
                    name: 'Live',
                    type: 'LiveStream',
                    target: document.querySelector('#div-video'),
                    constraints: {
                        width: {
                            min: 800
                        },
                        height: {
                            min: 600
                        },
                        aspectRatio: {
                            min: 1,
                            max: 2
                        },
                        facingMode: 'environment',
                        deviceId: this.selectedCamera.id
                    }
                },
                locator: {
                    patchSize: 'large',
                    halfSample: true
                },
                numOfWorkers: 0,
                decoder: {
                    readers: [
                        {
                            format: 'upc_reader',
                            config: { supplements: [] }
                        },
                        {
                            format: 'code_128_reader',
                            config: { supplements: [] }
                        },
                        {
                            format: 'code_39_reader',
                            config: { supplements: [] }
                        },
                        {
                            format: 'i2of5_reader',
                            config: { supplements: [] }
                        },
                        {
                            format: 'ean_reader',
                            config: { supplements: [] }
                        }
                    ]
                },
                locate: false
            },
            err => {
                if (err) {
                    return;
                }
                Quagga.start();
            });
        this.onDetection();
        this.onProcessed();
    }

    onCameraOff(): void {
        Quagga.stop();
        this.cameraOff.emit();
    }

    onProcessed(): void {
        Quagga.onProcessed(result => {
            const drawingCtx = Quagga.canvas.ctx.overlay;
            const drawingCanvas = Quagga.canvas.dom.overlay;
            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(0, 0,
                        parseInt(drawingCanvas.getAttribute('width'), 10), parseInt(drawingCanvas.getAttribute('height'), 10));
                    result.boxes.filter(box => {
                        return box !== result.box;
                    }).forEach(box => {
                        Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                            color: 'green',
                            lineWidth: 2
                        });
                    });

                    if (result.box) {
                        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                            color: '#00F',
                            lineWidth: 2
                        });
                    }

                    if (result.codeResult && result.codeResult.code) {
                        Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, {
                            color: 'red',
                            lineWidth: 3
                        });
                    }
                }
            }
        });
    }

    onDetection(): void {
        Quagga.onDetected(result => {
            Quagga.offProcessed();
            Quagga.offDetected();
            const code = result.codeResult.code;
            const decodedCodes = result.codeResult.decodedCodes;
            const isErrorFree = this.checkErrorCodes(decodedCodes);
            if (isErrorFree) {
                if (this.lastGoodUpcCount === 1) {
                    if (this.lastUpcProcessed !== code) {
                        Quagga.offProcessed();
                        Quagga.offDetected();
                        this.lastUpcProcessed = code;
                        this.lastGoodUpcCount = 0;
                        this.triggerSearch.emit(code);
                        this.onProcessed();
                        this.onDetection();
                    } else {
                        this.lastGoodUpcCount = 0;
                        this.onProcessed();
                        this.onDetection();
                    }
                } else {
                    this.lastGoodUpcCount += 1;
                    this.onProcessed();
                    this.onDetection();
                }
            } else {
                this.lastGoodUpcCount = 0;
                this.onProcessed();
                this.onDetection();
            }
        });
    }


    checkErrorCodes(decodedCodes): boolean {
        let countDecodedCodes = 0;
        let err = 0;
        decodedCodes.forEach(decodedCode => {
            if (decodedCode.error !== undefined) {
                countDecodedCodes++;
                err += parseFloat(decodedCode.error);
            }
        });
        if (countDecodedCodes === 0) {
            //Looks like we don't get error codes for barcode type 39
            return true;
        }
        if (err / countDecodedCodes < 0.12) {
            return true;
        } else {
            return false;
        }
    }
}
