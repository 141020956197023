import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'buyiq-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ExpansionPanelComponent implements OnInit {
    @Input() expanded: boolean;
    @Input() limitHeight: boolean;

    isExpanded: boolean;

    ngOnInit(): void {
        this.isExpanded = this.expanded;
    }

    onTogglePanel(event: MouseEvent): void {
        this.isExpanded = !this.isExpanded;
    }
}
