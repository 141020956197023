import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
    selector: 'buyiq-expansion-panel-title',
    template: '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ExpansionPanelTitleComponent {
    @HostBinding('class.expansion-panel-title') addClass = true;
}
